<template>
  <div id="user-create-tab-info">
    <!-- Avatar Row -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="flex items-start flex-col sm:flex-row">
          <img class="user-profile-img" :src="defaultUserUrl" />
        </div>
      </div>
    </div>

    <!-- Content Row -->
    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">
        <div class="mt-4">
          <vs-label text="Name" />
          <vs-input
            class="w-full"
            v-model="dataUser.name"
            v-validate="'required|alpha_spaces'"
            name="name"
          />
          <span class="text-danger text-sm" v-show="errors.has('name')">
            {{ errors.first('name') }}
          </span>
        </div>

        <div class="mt-4">
          <vs-label text="Password" />
          <vs-input
            class="w-full"
            v-model="dataUser.password"
            v-validate="'required|min:8'"
            name="password"
          />
          <span class="text-danger text-sm" v-show="errors.has('password')">
            {{ errors.first('password') }}
          </span>
        </div>

        <div class="mt-4">
          <vs-label text="Email" />
          <vs-input
            class="w-full"
            v-model="dataUser.email"
            type="email"
            v-validate="'required|email'"
            name="email"
          />
          <span class="text-danger text-sm" v-show="errors.has('email')">
            {{ errors.first('email') }}</span
          >
        </div>

        <div class="mt-4">
          <vs-label text="Mobile" />
          <vs-input class="w-full" v-model="dataUser.mobile" name="mobile" />
        </div>

        <div class="mt-4">
          <vs-label text="Country" />
          <v-select
            v-model="dataUser.country"
            :clearable="false"
            :options="countryList"
            v-validate="'required'"
            name="country"
          />
          <span class="text-danger text-sm" v-show="errors.has('country')">
            {{ errors.first('country') }}
          </span>
        </div>
      </div>

      <div class="vx-col md:w-1/2 w-full">
        <div class="mt-4">
          <vs-label text="Status" />
          <v-select
            v-model="dataUser.status"
            :clearable="false"
            :options="userStatusList"
            v-validate="'required'"
            name="status"
          />
          <span class="text-danger text-sm" v-show="errors.has('status')">
            {{ errors.first('status') }}
          </span>
        </div>

        <div class="mt-4">
          <vs-label text="Role" />
          <v-select
            v-model="dataUser.role"
            :clearable="false"
            :options="userRoleList"
            v-validate="'required'"
            name="role"
          />
          <span class="text-danger text-sm" v-show="errors.has('role')">
            {{ errors.first('role') }}
          </span>

          <small class="text-warning">
            *You can edit permissions only after adding a user.
          </small>
        </div>

        <div class="mt-4">
          <vs-label text="Region" />
          <v-select
            v-model="dataUser.region"
            :clearable="false"
            :options="regionList"
            name="region"
          />
        </div>
      </div>
    </div>

    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button
            class="ml-auto mt-2"
            @click="save"
            :disabled="!validateForm || isSending"
          >
            <span v-if="!isSending">Save Changes</span>
            <span v-else>Creating...</span>
          </vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import { mapActions } from 'vuex';

import { UserAction } from '@/store/actionTypes';
import moduleUser from '@/store/users/moduleUser';

import { UserStatus } from '@/enums/UserStatus';

import userTabAccountMixin from '@/mixins/userTabAccountMixin';

import { getMessageFromError } from '@/helpers';

export default {
  mixins: [userTabAccountMixin],
  components: {
    vSelect,
  },
  data() {
    return {
      isSending: false,

      dataUser: {
        name: null,
        email: null,
        mobile: null,
        password: null,
        country: null,
        status: UserStatus.ACTIVE,
        role: null,
        region: null,
      },

      defaultUserUrl: `${process.env.VUE_APP_API_URL}/default-user.png`,
    };
  },
  computed: {
    selectedStatus() {
      return this.dataUser.status.value;
    },
    selectedRole() {
      return this.dataUser.role.value;
    },
    selectedRegion() {
      return this.dataUser.region ? this.dataUser.region.value : null;
    },
    selectedCountry() {
      return this.dataUser.country.value;
    },
    validateForm() {
      return (
        !this.errors.any() &&
        this.dataUser.name !== null &&
        this.dataUser.name !== '' &&
        this.dataUser.email !== null &&
        this.dataUser.email !== '' &&
        this.dataUser.password !== null &&
        this.dataUser.country !== null &&
        this.dataUser.role !== null
      );
    },
  },
  methods: {
    ...mapActions('user', [UserAction.add]),

    registerStoreModules() {
      if (!moduleUser.isRegistered) {
        this.$store.registerModule('user', moduleUser);
        moduleUser.isRegistered = true;
      }
    },
    async save() {
      if (this.$gate.isKamRole) {
        return;
      }

      this.isSending = true;

      const obj = Object.assign({}, this.dataUser, {
        status: this.selectedStatus,
        role: this.selectedRole,
        country: this.selectedCountry,
        region: this.selectedRegion,
      });

      this.$vs.loading();

      try {
        const { message } = await this.addUser(obj);

        this.notifySuccess('User added', message);

        this.$router.push({ name: 'users-list' }).catch(() => {});
      } catch (error) {
        this.notifyError(error, getMessageFromError(error));

        this.isSending = false;
      }

      this.$vs.loading.close();
    },
  },
  created() {
    this.registerStoreModules();
  },
};
</script>

<style lang="scss" scoped>
.user-profile-img {
  width: 112px;
  height: 112px;
  border-radius: 100%;
}
</style>
