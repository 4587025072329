<template>
  <div id="page-user-create">
    <vx-card>
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab label="Account" icon-pack="feather" icon="icon-user">
            <div class="tab-text">
              <user-create-tab-account class="mt-4" :data="{}" />
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>

<script>
import UserCreateTabAccount from './UserCreateTabAccount';

export default {
  components: {
    UserCreateTabAccount,
  },
  data() {
    return {
      activeTab: 0,
    };
  },
};
</script>
